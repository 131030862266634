import {
  lessonTipSchema,
  lessonExampleSchema,
  readingLessonContentSchema,
} from "@natanada/prisma/zod";
import { z } from "zod";

import {
  zContentChallengeTypeField,
  zContentDifficultyField,
} from "../enums.js";

/**
 * LESSON PARENT LEVEL
 */

export const zLessonParentLevelSchema = z.object({
  parentLevel: z.object({
    id: z.string().nonempty("Invalid level id"),
  }),
});

/**
 * LESSON COMMON PROPERTIES & FIELDS
 */

export const zLessonPropertiesSchema = z.object({
  difficulty: zContentDifficultyField.default("BASIC"),
  xpReward: z
    .number({ message: "Please enter xp reward amount" })
    .positive()
    .min(0)
    .max(100),
});
export const zLessonTipFieldsSchema = z.object({
  id: z.string(),
  value: z.string().trim().nonempty("Please enter tip"),
});
export const zLessonExampleFieldsSchema = z.object({
  id: z.string(),
  sentence: z.string().trim().nonempty("Please enter sentence"),
  sentenceTranslation: z.string().trim().nonempty("Please enter translation"),
  sentenceTts: z.string().optional(),
});
export const zQuizOptionFieldsSchema = z.object({
  id: z.string(),
  value: z.string(),
});
export const zQuizSelectContentFieldsSchema = z.object({
  id: z.string(),
  title: z
    .string({
      message: "Please enter challenge title",
    })
    .trim()
    .nonempty("Please enter challenge title"),
  type: z.literal("SELECT"),
  sentence: z
    .string({
      message: "Please enter quiz sentence",
    })
    .trim()
    .nonempty("Please enter quiz sentence"),
  translation: z.string().optional(),
  correctOption: z.object(
    {
      ...zQuizOptionFieldsSchema.shape,
    },
    {
      message: "Please select the correct challenge choice/option",
    },
  ),
  options: z
    .array(zQuizOptionFieldsSchema)
    .min(2, "Please enter at least two challenge choices/options"),
});
export const zQuizFillContentFieldsSchema = z.object({
  id: z.string(),
  title: z
    .string({ message: "Please enter challenge title" })
    .trim()
    .nonempty("Please enter challenge title"),
  type: z.literal("FILL"),
  sentence: z
    .string({ message: "Please enter quiz sentence" })
    .trim()
    .nonempty("Please enter quiz sentence")
    .regex(
      /\[(.*?)\]/,
      "Looks like you forgot to wrap the 'gap' word in square brackets",
    ),
  completeCorrectSentence: z.string(),
  translation: z.string().optional(),
  options: z
    .array(zQuizOptionFieldsSchema)
    .min(2, "Please enter at least two challenge choices/options"),
});
export const zQuizContentFieldsSchema = z.discriminatedUnion("type", [
  zQuizFillContentFieldsSchema,
  zQuizSelectContentFieldsSchema,
]);

/**
 * LESSON OPERATIONS
 */

export const zLessonTDeleteSchema = z.object({
  id: z.string().nonempty("Please provide a valid letter lesson id"),
  type: zContentChallengeTypeField.default("LetterLesson"),
});
export const zLessonToUpdateSchema = z.object({
  lesson: z.object({
    id: z.string().nonempty("Invalid letter lesson id"),
  }),
});

/**
 * LETTER LESSON
 */

export const zLetterLessonFieldsSchema = z.object({
  title: z
    .string({ message: "Please enter letter in Korean" })
    .trim()
    .nonempty("Please enter letter in Korean"),
  titleTranslation: z
    .string({ message: "Please enter letter in English" })
    .trim()
    .nonempty("Please enter letter in English"),
  titleTts: z.string().optional(),
  letterAnimatedWriting: z.string().optional(),
  letterPronounceVideo: z.string().optional(),
  letterNotion: z.string().optional(),
  tips: z.array(lessonTipSchema).default([]),
  examples: z.array(lessonExampleSchema).default([]),
});
export const zCreateLetterLessonSchema = zLessonParentLevelSchema
  .merge(zLetterLessonFieldsSchema)
  .merge(zLessonPropertiesSchema);
export const zUpdateLetterLessonSchema = zLessonToUpdateSchema
  .merge(zLetterLessonFieldsSchema)
  .merge(zLessonPropertiesSchema);

/**
 * VOCABULARY LESSON
 */

export const zVocabularyLessonFieldsSchema = z.object({
  title: z
    .string({ message: "Please enter vocabulary in Korean" })
    .trim()
    .nonempty("Please enter vocabulary in Korean"),
  titleTranslation: z
    .string({ message: "Please enter vocabulary in English" })
    .trim()
    .nonempty("Please enter vocabulary in English"),
  explanation: z.string().trim().optional(),
  titleTts: z.string().optional(),
  vocabularyNotion: z.string().optional(),
  tips: z.array(lessonTipSchema).default([]),
  examples: z.array(lessonExampleSchema).default([]),
});
export const zCreateVocabularyLessonSchema = zLessonParentLevelSchema
  .merge(zVocabularyLessonFieldsSchema)
  .merge(zLessonPropertiesSchema);
export const zUpdateVocabularyLessonSchema = zLessonToUpdateSchema
  .merge(zVocabularyLessonFieldsSchema)
  .merge(zLessonPropertiesSchema);

/**
 * GRAMMAR LESSON
 */

export const zGrammarLessonFieldsSchema = z.object({
  title: z
    .string({ message: "Please enter grammar in Korean" })
    .trim()
    .nonempty("Please enter grammar in Korean"),
  titleTranslation: z
    .string({ message: "Please enter grammar in English" })
    .trim()
    .nonempty("Please enter grammar in English"),
  explanation: z.string().trim().optional(),
  grammarTts: z.string().optional(),
  grammarNotion: z.string().optional(),
  tips: z.array(lessonTipSchema).default([]),
  examples: z.array(lessonExampleSchema).default([]),
});
export const zCreateGrammarLessonSchema = zLessonParentLevelSchema
  .merge(zGrammarLessonFieldsSchema)
  .merge(zLessonPropertiesSchema);

export const zUpdateGrammarLessonSchema = zLessonToUpdateSchema
  .merge(zGrammarLessonFieldsSchema)
  .merge(zLessonPropertiesSchema);

/**
 * QUIZ LESSON
 */

export const zQuizFieldsSchema = z.object({
  title: z
    .string({ message: "Please enter quiz title" })
    .trim()
    .nonempty("Please enter quiz title"),
  content: z
    .array(zQuizContentFieldsSchema)
    .min(1, "Please add at least one quiz challenge"),
});
export const zCreateQuizSchema = zLessonParentLevelSchema
  .merge(zQuizFieldsSchema)
  .merge(zLessonPropertiesSchema);

export const zUpdateQuizSchema = zLessonToUpdateSchema
  .merge(zQuizFieldsSchema)
  .merge(zLessonPropertiesSchema);

/**
 * READING LESSON
 */

export const zReadingLessonFieldsSchema = z.object({
  title: z
    .string({ message: "Please enter reading title in Korean" })
    .trim()
    .nonempty("Please enter reading title in Korean"),
  titleTranslation: z
    .string({
      message: "Please enter reading title translation",
    })
    .trim()
    .nonempty("Please enter reading title translation"),
  headerImage: z.string().optional(),
  readTime: z
    .number({ message: "Please enter estimated reading time in minutes" })
    .positive("Please insert a number greater than 1"),
  readingTts: z.object({
    source: z.string().optional(),
    chapters: z.array(
      z
        .object({
          id: z.string(),
          start: z
            .number({ message: "Please enter chapter start time" })
            .nonnegative("Start time can't be less than 0"),
          end: z
            .number({
              message: "Please enter chapter end time",
            })
            .nonnegative("Please insure chapter end time is greater than 0"),
        })
        .refine((chapter) => chapter.start < chapter.end, {
          message: "Chapter start time should be less than end time",
          path: ["start"],
        })
        .refine((chapter) => chapter.end > chapter.start, {
          message: "Chapter end time should be greater than start time",
          path: ["end"],
        }),
      { message: "Please enter at least one audio chapter" },
    ),
  }),
  body: readingLessonContentSchema.shape.body.refine(
    (body) => body.blocks.length > 0,
    {
      message: "Please enter reading text",
      path: ["blocks"],
    },
  ),
});
export const zCreateReadingLessonSchema = zLessonParentLevelSchema
  .merge(zReadingLessonFieldsSchema)
  .merge(zLessonPropertiesSchema);
export const zUpdateReadingLessonSchema = zLessonToUpdateSchema
  .merge(zReadingLessonFieldsSchema)
  .merge(zLessonPropertiesSchema);
