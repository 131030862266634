import { tabsAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject } from "@chakra-ui/react";

const baseStyle: PartsStyleObject<typeof parts> = {
  tabpanel: {
    p: 0,
  },
};

const defaultProps = {
  size: "md",
  variant: "line",
  colorScheme: "brand",
};

const overrides = {
  parts,
  baseStyle,
  defaultProps,
};

export default overrides;
