import { ApolloClient, createQueryPreloader, from } from "@apollo/client";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import { API_URL } from "../constants";
import { cache } from "./cache";

const removeTypenameLink = removeTypenameFromVariables();
// File upload link
const uploadLink = createUploadLink({
  uri: `${API_URL}/graphql`,
  credentials: "include",
  headers: {
    // This request header is required for file uploads
    // https://www.apollographql.com/docs/apollo-server/security/cors/#preventing-cross-site-request-forgery-csrf
    "Apollo-Require-Preflight": "true",
  },
});
const link = from([removeTypenameLink, uploadLink]);

export const client = new ApolloClient({
  link,
  cache,
});

export const preloadQuery = createQueryPreloader(client);
