import * as React from "react";
import { Navigate, Route, createRoutesFromElements } from "react-router-dom";

import { WEB_SITE_URL } from "./constants";
import {
  requireAdminOrEditor,
  requirePublic,
  requireUser,
} from "./authentication/session.loader";
import { sentryCreateBrowserRouter } from "./lib/sentry";
import { ErrorPage } from "./pages/_error";

/**
 * Priority loaders
 */
import { loader as learnLoader } from "./pages/learn/loader";
import { loader as levelLoader } from "./pages/level/loader";
import { loader as challengeLoader } from "./pages/challenge/loader";
import { loader as collectionReviewLoader } from "./pages/collection-review/loader";

const routes = createRoutesFromElements(
  <Route
    lazy={() => import("./pages/root")}
    errorElement={<ErrorPage isRoot />}
  >
    {/* PRIVATE ROUTES */}
    <Route
      loader={async ({ request }) => {
        await requireUser({ fallbackTo: WEB_SITE_URL }, request);
        return null;
      }}
      lazy={() => import("./pages/learn/layout")}
    >
      <Route
        path="/"
        loader={learnLoader}
        lazy={() => import("./pages/learn")}
      />
    </Route>
    <Route
      loader={async ({ request }) => {
        await requireUser({ fallbackTo: "/sign-in" }, request);
        return null;
      }}
    >
      <Route lazy={() => import("./pages/onboarding/layout")}>
        <Route path="/onboarding">
          <Route index element={<Navigate to="meet-natanada" replace />} />
          <Route
            path="meet-natanada"
            lazy={() => import("./pages/meet-natanada")}
          />
        </Route>
      </Route>
      <Route lazy={() => import("./pages/meet-natanada/layout")}>
        <Route
          path="/meet-natanada"
          lazy={() => import("./pages/meet-natanada")}
        />
      </Route>
      <Route lazy={() => import("./pages/collection-review/layout")}>
        <Route
          path="/review/:idOrCollectionId"
          lazy={() => import("./pages/collection-review")}
          loader={collectionReviewLoader}
        />
      </Route>
      <Route lazy={() => import("./pages/level/layout")}>
        <Route
          path="/level/:levelId"
          lazy={() => import("./pages/level")}
          loader={levelLoader}
        />
      </Route>
      <Route lazy={() => import("./pages/stats/layout")}>
        <Route path="/stats" lazy={() => import("./pages/stats")} />
      </Route>
      <Route lazy={() => import("./pages/sign-out/layout")}>
        <Route path="/sign-out" lazy={() => import("./pages/sign-out")} />
      </Route>
    </Route>
    <Route
      loader={async ({ request }) => {
        await requireUser(
          { fallbackTo: "/sign-in", requireOnboarding: true },
          request,
        );
        return null;
      }}
      lazy={() => import("./pages/challenge/layout")}
    >
      <Route
        path="/challenge/:challengePathHash"
        lazy={() => import("./pages/challenge")}
        loader={challengeLoader}
      />
    </Route>
    <Route
      loader={async ({ request }) => {
        await requireUser(
          { fallbackTo: "/sign-in", allowRedirection: true },
          request,
        );
        return null;
      }}
      lazy={() => import("./pages/account-settings/layout")}
    >
      <Route path="/settings" lazy={() => import("./pages/account-settings")}>
        <Route index element={<Navigate to="profile" replace />} />
        <Route
          path="profile"
          lazy={() => import("./pages/account-settings/profile")}
        />
        <Route
          path="preferences"
          lazy={() => import("./pages/account-settings/preferences")}
        />
        <Route
          path="login-and-security"
          lazy={() => import("./pages/account-settings/login-and-security")}
        />
        <Route
          path="subscription"
          lazy={() => import("./pages/account-settings/subscription")}
        />
        <Route path="*" element={<Navigate to="." replace />} />
      </Route>
    </Route>

    {/* PUBLIC ROUTES WITH FALLBACK */}
    <Route
      loader={async ({ request }) => {
        await requirePublic(
          { fallbackTo: "/", allowRedirection: true },
          request,
        );
        return null;
      }}
    >
      <Route lazy={() => import("./pages/signup/layout")}>
        <Route path="/sign-up" lazy={() => import("./pages/signup")} />
      </Route>
      <Route lazy={() => import("./pages/signin/layout")}>
        <Route path="/sign-in" lazy={() => import("./pages/signin")} />
      </Route>
    </Route>
    {/* ALWAYS ROUTES PUBLIC */}
    <Route lazy={() => import("./pages/reset-password/layout")}>
      <Route
        path="/reset-password"
        lazy={() => import("./pages/reset-password")}
      />
    </Route>
    <Route lazy={() => import("./pages/verify-reset-password/layout")}>
      <Route
        path="/reset-password/:resetToken"
        lazy={() => import("./pages/verify-reset-password")}
      />
    </Route>
    <Route lazy={() => import("./pages/verify/layout")}>
      <Route path="/verify/error" lazy={() => import("./pages/verify/error")} />
    </Route>
    {/* CONTENT EDITORS ROUTES */}
    <Route
      loader={async () => {
        await requireAdminOrEditor();
        return null;
      }}
    >
      <Route lazy={() => import("./pages/studio/layout")}>
        <Route path="/studio" lazy={() => import("./pages/studio")}>
          {/* Manage collections */}
          <Route path="collections">
            <Route
              index
              lazy={() =>
                import("./pages/studio/manage-collections/collections")
              }
            />
            <Route
              path="new"
              lazy={() =>
                import("./pages/studio/manage-collections/new-collection")
              }
            />
            <Route
              path="edit/:collectionId"
              lazy={() =>
                import("./pages/studio/manage-collections/edit-collection")
              }
            />
          </Route>

          {/* Manage levels inside a given collection */}
          <Route path="collection/:collectionId/levels">
            <Route
              index
              lazy={() => import("./pages/studio/manage-levels/levels")}
            />
            <Route
              path="new"
              lazy={() => import("./pages/studio/manage-levels/new-level")}
            />
            <Route
              path="edit/:levelId"
              lazy={() => import("./pages/studio/manage-levels/edit-level")}
            />
          </Route>

          {/* Manage lessons (challenges) inside a given level */}
          <Route path="level/:levelId/lessons">
            <Route
              index
              lazy={() => import("./pages/studio/manage-lessons/lessons")}
            />
            <Route
              path="view/:type/:lessonId"
              lazy={() => import("./pages/studio/manage-lessons/view-lesson")}
            />
            <Route path="letter">
              <Route
                path="new"
                lazy={() =>
                  import("./pages/studio/manage-lessons/letter/create")
                }
              />
              <Route
                path="edit/:lessonId"
                lazy={() =>
                  import("./pages/studio/manage-lessons/letter/update")
                }
              />
            </Route>
            <Route path="vocabulary">
              <Route
                path="new"
                lazy={() =>
                  import("./pages/studio/manage-lessons/vocabulary/create")
                }
              />
              <Route
                path="edit/:lessonId"
                lazy={() =>
                  import("./pages/studio/manage-lessons/vocabulary/update")
                }
              />
            </Route>
            <Route path="grammar">
              <Route
                path="new"
                lazy={() =>
                  import("./pages/studio/manage-lessons/grammar/create")
                }
              />
              <Route
                path="edit/:lessonId"
                lazy={() =>
                  import("./pages/studio/manage-lessons/grammar/update")
                }
              />
            </Route>
            <Route path="quiz">
              <Route
                path="new"
                lazy={() => import("./pages/studio/manage-lessons/quiz/create")}
              />
              <Route
                path="edit/:lessonId"
                lazy={() => import("./pages/studio/manage-lessons/quiz/update")}
              />
            </Route>
            <Route path="reading">
              <Route
                path="new"
                lazy={() =>
                  import("./pages/studio/manage-lessons/reading/create")
                }
              />
              <Route
                path="edit/:lessonId"
                lazy={() =>
                  import("./pages/studio/manage-lessons/reading/update")
                }
              />
            </Route>
          </Route>

          {/* Manage content upload for notion (netlify cms) */}
          <Route
            path="content-upload"
            lazy={() => import("./pages/studio/content-upload")}
          />

          {/* Redirect to collections page from studio */}
          <Route index element={<Navigate to="collections" />} />
          {/* Catch-up all routes we don't have */}
          <Route path="*" element={<Navigate to="/studio" replace />} />
        </Route>
      </Route>
    </Route>
    {/* REDIRECTS */}
    <Route path="*" element={<Navigate to="/" replace />} />
  </Route>,
);

const router = sentryCreateBrowserRouter(routes, {
  future: { v7_relativeSplatPath: true },
});

export default router;
