import { z } from "@natanada/validation";

import { btoa, atob } from "../lib/base64";
import { ChallengeType } from "../generated/api/graphql";
import { CHALLENGE_TYPES } from "../constants";

interface ChallengePath {
  challengeType: ChallengeType;
  id: string;
}

interface HashResult {
  challengeType: ChallengeType | null;
  id: string;
}

type GetHashedChallengePath = (args: ChallengePath) => string;

export const zChallengePathHashSchema = z.object({
  challengeType: z.enum(CHALLENGE_TYPES as [ChallengeType], {
    message: "Challenge type is required",
  }),
  id: z
    .string({ message: "Challenge id is required" })
    .uuid({ message: "Challenge id is required" }),
});

export const getHashedChallengePath: GetHashedChallengePath = ({
  challengeType,
  id,
}) => {
  const challengePathString = `${challengeType}#${id}`;
  return btoa(challengePathString);
};

export const getChallengePathFromHash = (hash: string): HashResult => {
  // Decode the hash and split the string in "-" character
  const [rawChallengeType, rawId] = atob(hash).split("#");

  // Challenge type.
  let challengeType: ChallengeType | null = null;

  // Check if it is exists and matches challenge type
  if (
    rawChallengeType &&
    CHALLENGE_TYPES.includes(rawChallengeType as ChallengeType)
  ) {
    challengeType = rawChallengeType as ChallengeType;
  }

  // Challenge id
  const id = rawId ?? "";

  return {
    challengeType,
    id,
  };
};
