import { z } from "zod";

export const lessonTipSchema = z.object({
  id: z.string(),
  value: z.string(),
});

export const lessonExampleSchema = z.object({
  id: z.string(),
  sentence: z.string(),
  sentenceTranslation: z.string(),
  sentenceTts: z.string().optional(),
});

export const letterLessonContentSchema = z.object({
  letterAnimatedWriting: z.string().optional(),
  letterPronounceVideo: z.string().optional(),
  titleTts: z.string().optional(),
  letterNotion: z.string().optional(),
  tips: z.array(lessonTipSchema).default([]),
  examples: z.array(lessonExampleSchema).default([]),
});

export const vocabularyLessonContentSchema = z.object({
  explanation: z.string().optional(),
  titleTts: z.string().optional(),
  vocabularyNotion: z.string().optional(),
  tips: z.array(lessonTipSchema).default([]),
  examples: z.array(lessonExampleSchema).default([]),
});

export const grammarLessonContentSchema = z.object({
  grammarTts: z.string().optional(),
  explanation: z.string().optional(),
  grammarNotion: z.string().optional(),
  tips: z.array(lessonTipSchema).default([]),
  examples: z.array(lessonExampleSchema).default([]),
});

export const readingLessonContentSchema = z.object({
  headerImage: z.string().optional(),
  readingTts: z
    .object({
      source: z.string(),
      chapters: z.array(
        z.object({
          id: z.string(),
          start: z.number(),
          end: z.number(),
        }),
      ),
    })
    .optional(),
  body: z
    .object({
      version: z.string(),
      time: z.number(),
      blocks: z.array(
        z.object({
          id: z.string(),
          type: z.literal("paragraph"),
          data: z
            .object({
              id: z.string(),
              sentence: z.string(),
              value: z.string(),
              translation: z.string(),
              audioTts: z.string().optional(),
              display: z.enum(["inline", "block"]),
              words: z.array(
                z.discriminatedUnion("type", [
                  z.object({
                    id: z.string(),
                    type: z.literal("punctuation"),
                    value: z.string(),
                  }),
                  z.object({
                    id: z.string(),
                    type: z.literal("word"),
                    value: z.string(),
                    translation: z.string(),
                    audioTts: z.string().optional(),
                  }),
                ]),
              ),
            })
            .and(
              z.discriminatedUnion("mode", [
                z.object({ mode: z.literal("normal") }),
                z.object({
                  mode: z.literal("dialogue"),
                  dialogue: z.object({
                    character: z.string(),
                    characterImage: z.string(),
                  }),
                }),
              ]),
            ),
        }),
      ),
    })
    .default({ time: 0, version: "", blocks: [] }),
});

const quizOptionSchema = z.object({
  id: z.string(),
  value: z.string(),
});
const quizFillContentSchema = z.object({
  id: z.string(),
  title: z.string(),
  type: z.literal("FILL"),
  sentence: z.string(),
  completeCorrectSentence: z.string(),
  translation: z.string().optional(),
  options: z.array(quizOptionSchema),
});
const quizSelectContentSchema = z.object({
  id: z.string(),
  title: z.string(),
  type: z.literal("SELECT"),
  sentence: z.string(),
  translation: z.string().optional(),
  correctOption: quizOptionSchema,
  options: z.array(quizOptionSchema),
});
export const quizContentSchema = z.array(
  z.discriminatedUnion("type", [
    quizFillContentSchema,
    quizSelectContentSchema,
  ]),
);

export type LessonTipSchema = z.infer<typeof lessonTipSchema>;
export type LessonExampleSchema = z.infer<typeof lessonExampleSchema>;
export type LetterLessonContentSchema = z.infer<
  typeof letterLessonContentSchema
>;
export type VocabularyLessonContentSchema = z.infer<
  typeof vocabularyLessonContentSchema
>;
export type GrammarLessonContentSchema = z.infer<
  typeof grammarLessonContentSchema
>;
export type ReadingLessonContentSchema = z.infer<
  typeof readingLessonContentSchema
>;

export type QuizContentSchema = z.infer<typeof quizContentSchema>;
