import { InMemoryCache } from "@apollo/client";

import introspectionResult from "../generated/introspection";

// In memory cache init
export const cache = new InMemoryCache({
  // Map the name of an interface or union type
  // to the type that implement or belong to it
  // https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
  possibleTypes: introspectionResult.possibleTypes,
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
  typePolicies: {
    LetterLessonContent: {
      keyFields: false,
    },
    VocabularyLessonContent: {
      keyFields: false,
    },
    GrammarLessonContent: {
      keyFields: false,
    },
    ReadingLessonContent: {
      keyFields: false,
    },
    QuizContent: {
      keyFields: false,
    },
    CollectionReviewContent: {
      keyFields: false,
    },
    LessonExample: {
      keyFields: false,
    },
    LessonTip: {
      keyFields: false,
    },
  },
});
