import type { GlobalStyles } from "@chakra-ui/theme-tools";

const styles: GlobalStyles = {
  global: () => ({
    body: {
      color: "black",
      background: "#dfe3f1",
      // TODO Workaround for customizing toast styles (remove when toasts can be themeable)
      "div.chakra-portal div[id^='chakra-toast-manager-']": {
        py: "var(--sizes-header-height, 0px)",
      },
      // Sentry feedback dialog style overrides
      "#sentry-feedback": {
        "--font-family": "var(--fonts-body)",
        "--dialog-border-radius": "var(--radii-md)",
        "--dialog-inset": 1,
        "--dialog-border": "none",
        "--dialog-box-shadow": "var(--shadows-card-md)",
        "--button-primary-background": "var(--colors-brand-500)",
        "--button-primary-hover-background": "var(--colors-brand-500)",
        "--interactive-filter": "none",
        "--input-focus-outline": "1px auto var(--colors-brand-500)",
        "--input-border-radius": "5px",
        "--button-primary-border": "2px solid var(--colors-brand-500)",
        "--button-border": "2px solid var(--colors-gray-200)",
        "--button-font-size": "var(--fontSizes-md)",
        "--button-primary-border-radius": "var(--radii-full)",
        "--button-border-radius": "var(--radii-full)",
      },
    },
  }),
};

export default styles;
