import { z } from "zod";

import { zContentChallengeTypeField } from "../enums.js";

/**
 * CHALLENGE RATING
 */

export const zRateChallengeSchema = z.object({
  challengeToRate: z.object({
    id: z
      .string({ message: "Challenge id is required" })
      .nonempty("Challenge id is required"),
    type: zContentChallengeTypeField,
  }),
  rating: z
    .number()
    .min(-1)
    .max(1)
    .refine(
      (rating) => rating === 1 || rating === -1,
      "Only number 1 or -1 is allowed",
    ),
  feedback: z.string().nullish(),
});

export const zUpdateChallengeRatingSchema = z.object({
  id: z
    .string({ message: "Rating id is required" })
    .nonempty("Rating id is required"),
  rating: z
    .number()
    .min(-1)
    .max(1)
    .refine(
      (rating) => rating === 1 || rating === -1,
      "Only number 1 or -1 is allowed",
    ),
});

/**
 * STATS
 */

export const zYearlyAccomplishedCalendarSchema = z.object({
  year: z.number({ message: "Please provide a valid year value" }),
});

/**
 * STREAK
 */

export const zStreakCalendarRangeSchema = z
  .object({
    from: z.coerce.string().date("Please provide a valid from date"),
    to: z.coerce.string().date("Please provide a valid to date"),
  })
  .refine((range) => new Date(range.to) > new Date(range.from), {
    message: "To date value should be greater than from date value",
  });
