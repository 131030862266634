import { z } from "zod";

import { zContentDifficultyField } from "../enums.js";

/**
 * LEVEL PARENT COLLECTION
 */

export const zLevelParentCollectionSchema = z.object({
  collectionId: z.string({ message: "Unknown collection id was provided" }),
});

/**
 * LEVEL COMMON PROPERTIES
 */

export const zLevelPropertiesSchema = z.object({
  isPremium: z.boolean().default(true),
  difficulty: zContentDifficultyField.default("BASIC"),
  xpReward: z
    .number({ message: "Please enter xp reward amount" })
    .positive()
    .min(0)
    .max(100)
    .default(20),
});

/**
 * LEVEL UNIQUE PROPERTIES
 */

export const zLevelUniqueSchema = z.object({
  id: z.string().nonempty("Please provide a valid level id"),
});

/**
 * LEVEL OPERATIONS
 */

export const zLevelToDeleteSchema = z.object({
  id: zLevelUniqueSchema.shape.id,
});

/**
 * LEVEL
 */

export const zLevelFieldsSchema = z.object({
  title: z.string({ message: "Please add level title" }),
  avatar: z.string({ message: "Please upload the level image thumbnail" }),
});
export const zCreateLevelSchema = zLevelParentCollectionSchema
  .merge(zLevelFieldsSchema)
  .merge(zLevelPropertiesSchema);
export const zUpdateLevelSchema = zLevelUniqueSchema
  .pick({ id: true })
  .merge(zLevelFieldsSchema)
  .merge(zLevelPropertiesSchema);
