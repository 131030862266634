import * as React from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";
import posthog from "posthog-js";

import type { CurrentUserQuery } from "../../generated/api/graphql";

export { posthog } from "posthog-js";
export * from "posthog-js/react";

export const initPosthog = () => {
  if (
    import.meta.env.REACT_APP_POSTHOG_KEY &&
    import.meta.env.REACT_APP_POSTHOG_HOST
  ) {
    posthog.init(import.meta.env.REACT_APP_POSTHOG_KEY, {
      api_host: import.meta.env.REACT_APP_POSTHOG_HOST,
      capture_pageview: false,
    });
  }
};

export const posthogIdentifyUser = (
  currentUser: CurrentUserQuery["currentUser"],
) => {
  posthog.identify(currentUser?.username, {
    fullName: currentUser?.fullName,
    email: currentUser?.email,
    role: currentUser?.role,
    plan: currentUser?.subscription.plan,
  });
};

export const posthogClearUser = () => {
  posthog.reset();
};

/**
 * Capture an event and send it to Posthog
 */
export const posthogCapture = (
  event: string,
  properties?: Record<string, any>,
) => {
  posthog.capture(event, properties);
};

export const usePosthogPageView = () => {
  const currentLocation = useLocation();

  const previousLocation = usePrevious(currentLocation);

  React.useEffect(() => {
    if (previousLocation?.pathname || previousLocation?.search) {
      let url = `${window.origin}${previousLocation.pathname}`;
      if (previousLocation?.search) {
        url += `${previousLocation.search}`;
      }
      posthogCapture("$pageleave", {
        $current_url: url,
      });
    }
  }, [previousLocation?.pathname, previousLocation?.search]);

  React.useEffect(() => {
    posthogCapture("$pageview");
  }, [currentLocation.pathname, currentLocation.search]);
};
