import { z } from "zod";

import { zContentDifficultyField, zContentStatusField } from "../enums.js";

/**
 * COLLECTION COMMON PROPERTIES
 */

export const zCollectionPropertiesSchema = z.object({
  isPremium: z.boolean().default(true),
  difficulty: zContentDifficultyField.default("BASIC"),
  xpReward: z
    .number({ message: "Please enter xp reward amount" })
    .positive()
    .min(0)
    .max(100)
    .default(30),
});

/**
 * COLLECTION UNIQUE PROPERTIES
 */

export const zCollectionUniqueSchema = z.object({
  id: z.string().nonempty("Please provide a valid collection id"),
});

/**
 * COLLECTION OPERATIONS
 */

export const zCollectionToDeleteSchema = z.object({
  id: zCollectionUniqueSchema.shape.id,
});

/**
 * COLLECTION
 */

export const zCollectionFieldsSchema = z.object({
  title: z.string().nonempty("Please add collection title"),
  description: z.string().nonempty("Please add collection description"),
});
export const zCreateCollectionSchema = zCollectionFieldsSchema.merge(
  zCollectionPropertiesSchema,
);
export const zUpdateCollectionSchema = zCollectionUniqueSchema
  .pick({ id: true })
  .merge(zCollectionFieldsSchema)
  .merge(zCollectionPropertiesSchema);
export const zUpdateCollectionStatusSchema = zCollectionUniqueSchema
  .pick({ id: true })
  .merge(
    z.object({
      newStatus: zContentStatusField.default("DRAFT"),
    }),
  );
