import { z } from "zod";

/**
 * AUTH FIELDS
 */

export const zUsernameField = z
  .string({ message: "Please enter a valid username" })
  .trim()
  .regex(
    /^[a-z0-9_-]*$/,
    "Only lowercase letters, numbers, dashes, and underscores try again",
  )
  .min(6, "Username is too short")
  .max(20, "Username must be between 6 and 20 characters");
export const zFullNameField = z
  .string({
    message: "Please enter your full name.",
  })
  .trim()
  .nonempty("Please enter your full name.");
export const zEmailField = z
  .string({ message: "Your email is required" })
  .email("Please enter a valid email")
  .trim()
  .toLowerCase();
export const zPasswordField = z
  .string({ message: "Password is required" })
  .min(8, "Password must be at least 8 characters")
  .regex(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Password must contain at least one number, one uppercase, and one lowercase letter",
  );
export const zReTokenField = z
  .string({ message: "Please check the captcha checkbox" })
  .nullable();

/**
 * AUTH
 */

export const zSignUpSchema = z.object({
  fullName: zFullNameField,
  email: zEmailField,
  password: zPasswordField,
  reToken: zReTokenField,
});
export const zSignInSchema = z.object({
  email: zEmailField,
  password: z.string({ message: "Please enter your password" }),
  reToken: zReTokenField,
});
export const zRequestResetPasswordSchema = z.object({
  email: zEmailField,
  reToken: zReTokenField,
});
export const zResetPasswordSchema = z.object({
  password: zPasswordField,
  reToken: zReTokenField.and(z.string()),
  resetToken: z.string({ message: "This token is either invalid or expired!" }),
});
export const zVerifyResetPasswordSchema = z
  .object({
    newPassword: zPasswordField,
    confirmPassword: z.string(),
    reToken: zReTokenField,
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Your password didn't match",
    path: ["confirmPassword"],
  });

/**
 * PASSWORD
 */

export const zCreatePasswordSchema = z
  .object({
    newPassword: zPasswordField,
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Your password didn't match",
    path: ["confirmPassword"],
  });
export const zUpdatePasswordSchema = z
  .object({
    oldPassword: z.string(),
    newPassword: zPasswordField,
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Your password didn't match",
    path: ["confirmPassword"],
  });
