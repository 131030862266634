/* eslint-disable @typescript-eslint/only-throw-error */
import { redirect } from "react-router-dom";
import type { LoaderFunctionArgs } from "react-router-dom";

import {
  zChallengePathHashSchema,
  getChallengePathFromHash,
} from "../../helpers/hashed-challenge-path";
import {
  ChallengeByHashDocument,
  ProgressByChallengeHashDocument,
} from "../../generated/api/graphql";
import { preloadQuery } from "../../apollo-client";

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const hashResults = getChallengePathFromHash(
    String(params.challengePathHash),
  );

  const { success } =
    await zChallengePathHashSchema.safeParseAsync(hashResults);
  if (!success) {
    throw redirect("/404");
  }

  const preloadedQuery = await Promise.all([
    hashResults.challengeType,
    preloadQuery(ChallengeByHashDocument, {
      variables: { challengePathHash: String(params.challengePathHash) },
      fetchPolicy: "cache-first",
    }),
    preloadQuery(ProgressByChallengeHashDocument, {
      variables: { hash: String(params.challengePathHash) },
      fetchPolicy: "network-only",
    }),
  ]);
  return preloadedQuery;
};
