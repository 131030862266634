import { z } from "zod";

import { zEmailField, zFullNameField, zUsernameField } from "./auth.js";

export const zUserProfileSchema = z.object({
  username: zUsernameField,
  fullName: zFullNameField,
  email: zEmailField,
});
export const zUserPreferencesSchema = z.object({
  romanizationEnabled: z
    .boolean({ message: "A boolean value is required" })
    .default(false),
  timeZone: z
    .string({ message: "Please select a valid timezone" })
    .nonempty("Please select a valid timezone"),
});
