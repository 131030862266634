import { z } from "zod";

/**
 * ENUMS
 */

export const zContentDifficultyField = z.enum(["BASIC", "MEDIUM", "HARD"], {
  message: "Please select a difficulty value",
});
export const zContentChallengeTypeField = z.enum([
  "LetterLesson",
  "VocabularyLesson",
  "GrammarLesson",
  "ReadingLesson",
  "Quiz",
]);
export const zContentStatusField = z.enum(
  ["DRAFT", "IN_REVIEW", "REVIEWED", "PUBLISHED"],
  {
    message:
      "Unrecognized status, Please use one of: DRAFT, IN_REVIEW, REVIEWED, or PUBLISHED",
  },
);
