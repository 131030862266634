import type { LoaderFunctionArgs } from "react-router-dom";

import { CollectionReviewByIdDocument } from "../../generated/api/graphql";
import { preloadQuery } from "../../apollo-client";

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = ({ params }: LoaderFunctionArgs) => {
  const preloadedQuery = preloadQuery(CollectionReviewByIdDocument, {
    variables: {
      input: {
        collectionId: params.idOrCollectionId,
        id: params.idOrCollectionId,
      },
    },
  });

  return preloadedQuery;
};
