import * as React from "react";
import { Box, ThemingProps, useStyleConfig } from "@chakra-ui/react";

import { LogoIcon, LogoMarkIcon, LogoWordMarkIcon } from "./icon";
import type { LogoIconProps } from "./icon";

export interface LogoProps
  extends Omit<LogoIconProps, "orientation">,
    Omit<ThemingProps<"Logo">, "variant"> {
  variant?: "complete" | "mark" | "wordMark";
}

const iconMap = {
  complete: LogoIcon,
  mark: LogoMarkIcon,
  wordMark: LogoWordMarkIcon,
};

/**
 * Application Logo
 */
export const Logo = function Logo({
  ref,
  ...props
}: LogoProps & { ref?: React.RefObject<HTMLDivElement | null> }) {
  const { variant = "complete", title, ...rest } = props;
  const styles = useStyleConfig("Logo", rest);

  const Icon = iconMap[variant];
  return (
    <Box ref={ref} display="flex" alignItems="center" justifyContent="center">
      <Icon {...rest} title={title} __css={styles} />
    </Box>
  );
};
