import { preloadQuery } from "../../apollo-client";
import {
  ResumeCurrentProgressDocument,
  LearnCollectionsDocument,
  StreakOverviewDocument,
} from "../../generated/api/graphql";

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async () => {
  const preLoadedQueries = await Promise.all([
    preloadQuery(ResumeCurrentProgressDocument, {
      fetchPolicy: "cache-and-network",
    }),
    preloadQuery(LearnCollectionsDocument, {
      fetchPolicy: "cache-and-network",
    }),
    preloadQuery(StreakOverviewDocument, {
      fetchPolicy: "cache-and-network",
    }),
  ]);
  return preLoadedQueries;
};
