import type { LoaderFunctionArgs } from "react-router-dom";

import { preloadQuery } from "../../apollo-client";
import { LevelDocument } from "../../generated/api/graphql";

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return preloadQuery(LevelDocument, {
    variables: { where: { id: params.levelId } },
    fetchPolicy: "cache-and-network",
  });
};
